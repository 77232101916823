div.DraftEditor-root {
    border: 1px solid rgb(183, 181, 182);
    border-radius: 15px;
    background-color: transparent;
    height: 200px;
    width: 100%;
    margin-bottom: 10px;
    overflow-y: auto;
}

div.DraftEditor-editorContainer,
  div.public-DraftEditor-content {
    height: 100%;
    padding: 5px;
    overflow: auto;    
}

.selected-button { 
  background-color: #8dc572 !important;

}
  
.ql-snow .ql-picker.ql-font, .ql-bubble .ql-picker.ql-font {
  width: 130px;
}
/* Set dropdown font-families */

.ql-editor * {
  font-family: Montserrat !important;  
  font-size: 16px;
}

.ql-editor ol {
  padding: 0 !important;
  margin: 0 !important;
}

.ql-toolbar.ql-snow , .ql-container.ql-snow
{
  border: 1px solid rgb(112, 195, 141) !important;
  border-radius: 16px;
  margin-bottom: 15px;
}

.ql-container.ql-snow{
  height:200px;
}

.ql-snow .ql-tooltip.ql-flip {  
  margin-left:70px;
  z-index: 0;
}

.addModalEditorWrapper {
  display: flex;
  flex-flow: column;
  border: 1px solid #E9E7E8 !important;
  border-radius: 16px;
}

.addModalEditorWrapper .ql-toolbar, .ql-container.ql-snow {
  margin-bottom: 0;
}

.addModalEditorWrapper .ql-container.ql-snow {
  border: none !important;
}

.addModalEditorWrapper .ql-editor {
  border: none;
}

.addModalEditorWrapper .ql-toolbar {
  order: 1;
  border-color: #E9E7E8 !important;
  border-bottom: none !important;
  border-right: none !important;
  border-left: none !important;
}

.addModalEditorWrapper .ql-formats {
  margin-right: 0 !important;
  padding: 0 2px;
}